var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: "email-contact-centre" } },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c(
            "div",
            { staticClass: "modal-form" },
            [
              _c("button", {
                staticClass: "close",
                attrs: { "data-dismiss": "modal" }
              }),
              _vm.message
                ? _c(
                    "div",
                    {
                      staticClass: "alert mb-4",
                      class: _vm.alertClass,
                      attrs: { role: "alert" }
                    },
                    [_vm._v(" " + _vm._s(_vm.message) + " ")]
                  )
                : _vm._e(),
              _c("h5", { staticClass: "mb-4" }, [
                _vm._v("Email Contact Centre")
              ]),
              _c("ValidationObserver", {
                ref: "emailForm",
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      return [
                        _c(
                          "form",
                          {
                            attrs: {
                              novalidate: "",
                              enctype: "multipart/form-data"
                            },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submitEmailForm($event)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    name: "Job Number",
                                    rules: "required|integer"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(v) {
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.jobNumber,
                                                  expression: "jobNumber"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid":
                                                  v.invalid && v.validated
                                              },
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Job Number (starts with 237)",
                                                maxlength: "10",
                                                pattern: "^237\\d{7}$"
                                              },
                                              domProps: {
                                                value: _vm.jobNumber
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.jobNumber =
                                                    $event.target.value
                                                }
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "invalid-feedback text-left"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(v.errors[0]) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-control file-selector",
                                  attrs: { for: "" }
                                },
                                [
                                  _vm.attachedFile == false
                                    ? _c("span", [_vm._v("Attachment")])
                                    : _c("span", [
                                        _vm._v(
                                          " " + _vm._s(_vm.attachedFile.name)
                                        )
                                      ]),
                                  _c("ValidationProvider", {
                                    attrs: { name: "invoice", rules: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(v) {
                                            return [
                                              _c("input", {
                                                attrs: {
                                                  accept:
                                                    "application/pdf, image/png, image/jpg, image/*, image/jpeg, application/doc, .docx",
                                                  type: "file",
                                                  id: "fileInput"
                                                },
                                                on: { change: _vm.selectFile }
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "invalid-feedback text-left"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(v.errors[0]) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              ),
                              _c("p", { staticClass: "text-left note" }, [
                                _vm._v(
                                  "*Acceptable forms of upload formats are: png, jpg, jpeg, pdf, doc, docx"
                                )
                              ])
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "E-mail",
                                rules: "required|email",
                                slim: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(v) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.email,
                                                  expression: "email"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid":
                                                  v.invalid && v.validated
                                              },
                                              attrs: {
                                                type: "email",
                                                placeholder:
                                                  "Customer Email address"
                                              },
                                              domProps: { value: _vm.email },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.email =
                                                    $event.target.value
                                                }
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group-prepend"
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-alt btn-primary theme-bg",
                                                    attrs: {
                                                      type: "submit",
                                                      disabled:
                                                        invalid ||
                                                        _vm.profileFormLoading,
                                                      id:
                                                        "product-details-email-button"
                                                    }
                                                  },
                                                  [
                                                    _c("span", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.loading,
                                                          expression: "loading"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "spinner-grow spinner-grow-sm",
                                                      staticStyle: {
                                                        width: "1.0625rem",
                                                        height: "1.0625rem"
                                                      },
                                                      attrs: {
                                                        role: "status",
                                                        "aria-hidden": "true"
                                                      }
                                                    }),
                                                    _vm._v(" Send ")
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "invalid-feedback text-left"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(v.errors[0]) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }