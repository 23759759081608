<template>
  <BaseLayout>
    <div id="content" v-if="product">
      <div class="breadcrumb-wrapper">
        <div class="container">
          <ul class="breadcrumb">
            <li><router-link to="/">Home</router-link></li>
            <li>
              <router-link to="/select-category">{{
                product.category.tn_parent.name
                }}</router-link>
            </li>
            <li>
              <router-link to="/select-sub-category">{{
                product.category.name
                }}</router-link>
            </li>
            <li>
              {{ product.brand.name }} {{ product.series }} {{ product.code }}
              {{ product.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="product-wrapper">
            <div class="product">
              <div class="row">
                <div class="col-lg-4 col-md-5 column mb-lg-0">
                  <div class="slider product-slider">
                    <figure>
                      <img :src="'https://media3.bosch-home.com/Product_Shots/600x337/' +
                        product.featured_image +
                        '.jpg'
                        " :alt="product.code" style="width: auto; height: auto" />
                    </figure>
                  </div>
                </div>
                <div class="col-lg-8 col-md-7">
                  <div class="details">
                    <h4>
                      {{ product.brand.name }} {{ product.series }}
                      {{ product.code }} {{ product.name }}
                    </h4>
                    <div class="meta">
                      <span class="product-code">Product code: {{ product.code }}</span>
                    </div>
                    <span v-if="product.is_featured" class="featured-tag">Buy Direct Model</span>
                    <div class="row align-items-end">
                      <div class="col-lg-8">
                        <!-- <h5>Key Product Features</h5>
                        <ul class="features">
                          <li
                            v-for="feature in keyFeatures"
                            v-bind:key="feature.value"
                          >
                            {{ feature.attribute.name }} - {{ feature.value }}
                          </li>
                        </ul> -->
                      </div>
                    </div>
                    <hr />
                    <div class="text-lg-right product-buttons">
                      <a class="theme-bg btn btn-primary-light mb-lg-0 mr-2" @click="addProductForComparision(product)"
                        v-if="productComparision()" v-show="isSelectedForCompare" id="compare-product-button">Compare
                        product</a>
                      <b-button v-if="requiredProfileType" class="btn btn-primary bg-primary-light mr-2"
                        @click="generateUrl">
                        Generate URL
                      </b-button>
                      <button class="theme-bg btn btn-primary" data-toggle="modal" data-target="#email-product">
                        Email Customer
                      </button>
                      <button class="theme-bg btn btn-primary" data-toggle="modal" data-target="#email-contact-centre">
                        Email Contact Centre
                      </button>
                      <a class="theme-bg btn btn-primary-light mb-lg-0 mr-2"
                        :href="'https://media3.bosch-home.com/Documents/specsheet/en-GB/' + product.code + '.pdf'"
                        target="_blank">Download Specification</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabs1">
              <ul class="nav nav-tabs" role="tablist">
                <li>
                  <a class="active" data-toggle="tab" href="#tab1" role="tab">Product Info</a>
                </li>
                <li>
                  <a data-toggle="tab" href="#tab2" role="tab">Specification</a>
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                  <perfect-scrollbar>
                    <div v-if="product.description == '<div></div>'">Product Information currently not available</div>
                    <div v-else v-html="product.description"></div>
                  </perfect-scrollbar>
                </div>
                <div class="tab-pane fade" id="tab2" role="tabpanel">
                  <perfect-scrollbar>
                    <div v-if="product.specifications == '<div>\n  <ul></ul>\n</div>'">Product Specification currently
                      not
                      available</div>
                    <p v-else v-html="product.specifications"></p>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="section pb-0">
        <div class="container">
          <div class="product-wrapper">
            <div class="row">
              <template v-if="similarProductList.length > 0 || (successorProduct && successorProduct.name != '')">
                <div class="col-lg-3">
                  <header class="mb-4">
                    <h3 class="theme-text">Successor model</h3>
                  </header>

                  <div v-if="successorProduct && successorProduct.name != ''" class="successor-product">
                    <div class="product theme-bg">
                      <div class="image">
                        <img :src="'https://media3.bosch-home.com/Product_Shots/600x337/' +
                          successorProduct.featured_image +
                          '.jpg'
                          " alt="" />
                        <span v-if="product.is_featured" class="featured-tag rotated">Buy Direct Model</span>
                      </div>
                      <div class="details">
                        <h4>
                          {{ successorProduct.brand.name }}
                          {{ successorProduct.series }}
                          {{ successorProduct.code }}
                          {{ successorProduct.name }}
                        </h4>
                        <router-link :to="/product/ + successorProduct.id" @click.native="$scrollToTop"
                          :id="successorProduct.code + '-successor-view-button'" class="btn btn-primary">
                          View
                        </router-link>
                        <div class="info-text">
                          <i class="icon">
                            <img src="./../../assets/images/icon5.svg" alt="img">
                          </i>
                          <span>Latest Replacement Model</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="successor-product mb-4 mb-lg-0">
                    <div class="product theme-bg">
                      <div class="details">
                        <p>No direct successor available. Please use similar products or the product finder
                          to offer a replacement.</p>
                        <!-- <a href="mailto:cog-drsengineerleads@bshg.com?subject=Product%20Details%20%E2%80%93%20%5BINSERT%20JOB%20NUMBER%20237%E2%80%A6%5D%20%E2%80%93%20No%20Available%20Replacement&body=%0D%0AHi%20D2C%20Team%2C%0D%0A%0D%0AThis%20customer%E2%80%99s%20appliance%20is%20BER%2C%20please%20contact%20them%20to%20discuss%20replacement%20options%3A%0D%0A%0D%0AJob%20Number%3A%0D%0A%0D%0ACustomer%20Email%20Address%3A%0D%0A"
                          class="btn btn-link contact-center-btn mr-2" id="help-request-email-button">Send to Contact
                          Centre</a> -->
                        <button class="theme-bg btn btn-link contact-center-btn" data-toggle="modal"
                          data-target="#email-contact-centre">
                          Email Contact Centre
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9">
                  <div v-if="similarProductList.length > 0">
                    <header class="mb-4">
                      <h3 class="theme-text">Similar products</h3>
                    </header>
                    <div class="similar-products">
                      <ul class="list1 three-col" id="similar-product-list">
                        <li v-for="(product, index) in similarProductList" :key="index">
                          <div class="product">
                            <div class="image">
                              <img :src="'https://media3.bosch-home.com/Product_Shots/600x337/' +
                                product.featured_image +
                                '.jpg'
                                " :alt="product.code" />
                              <span v-if="product.is_featured" class="featured-tag rotated">Buy Direct Model</span>
                            </div>
                            <div class="details">
                              <h4>
                                {{ product.brand.name }} {{ product.series }}
                                {{ product.code }} {{ product.name }}
                              </h4>
                              <router-link :to="/product/ + product.id" :id="product.code + '-successor-view-button'"
                                class="btn btn-primary" replace>
                                View</router-link>
                              <div class="features-info">
                                <p>Exact Matched Product Features:</p>
                                <ul>
                                  <li
                                    v-for="category_attribute in similarAttributes(product.category.category_attributes)"
                                    :key="category_attribute">
                                    <i class="icon">
                                      <img src="./../../assets/images/icon6.svg" alt="img">
                                    </i>
                                    {{ category_attribute.attribute.name }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <b-pagination v-if="similarProductList.length > 0" v-model="currentPage" :total-rows="rows"
                    :per-page="perPage" class="justify-content-end" aria-controls="similar-product-list">
                  </b-pagination>
                </div>
              </template>
              <template v-else>
                <div class="col-lg-12">
                  <p class="text-center text-primary font-weight-bold">No direct successor or similar products
                    available.
                    Please use product finder to offer a
                    replacement.</p>
                </div>
              </template>

            </div>
          </div>
          <hr />
        </div>

      </section>

      <section class="section retailers">
        <div class="container">
          <header class="text-center mb-4">
            <h3 class="theme-text">Retailers available to purchase</h3>
          </header>

          <ul class="list1">
            <li v-for="retailer in retailers" :key="retailer.RetailerId" class="mb-4">
              <div class="retailer">
                <div class="image">
                  <img :src="retailer.RetailerLogoUrl" :alt="retailer.FriendlyName" />
                </div>
                <div class="details">
                  <span class="price">{{ retailer.RetailerCurrencySymbol }}{{ retailer.Price }}</span>
                  <div class="in-stock" v-if="retailer.Availability == 'InStock'">
                    <i class="fa fa-check"></i> In Stock
                  </div>
                  <div class="in-stock not" v-else>
                    <i class="fa fa-times"></i> Out of Stock
                  </div>

                  <a :href="retailer.ClickThruUrl" class="btn btn-secondary-dark theme-bg" target="_blank">View
                    Retailer</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <EmailProductInfoModal :productId="product.id" :currentUser="this.currentUser" />
      <EmailContactCentreModal />
      <b-modal id="generate-url-popup" centered title="Generated URL" busy="true" hide-footer>
        <div class="form-control d-flex align-items-center testing-code px-3 mb-2">
          <span class="code">{{ generatedUrl }}</span>
          <input type="hidden" id="generated-url" :value="generatedUrl">
        </div>
        <div v-if="urlCopiedSuccessfully" class="text-success mb-3 font-weight-bold">URL copied to clipboard
          successfully!
        </div>
        <div class="text-right mb-2">
          <button class="btn btn-secondary-dark mr-2"
            @click="$bvModal.hide('generate-url-popup'); urlCopiedSuccessfully = false">
            Cancel
          </button>
          <button class="btn btn-primary" @click.stop.prevent="copyGeneratedUrl">
            Copy
          </button>
        </div>
      </b-modal>
    </div>
  </BaseLayout>
</template>
<script>
import _ from "lodash";
import BaseLayout from "@/layouts/base";
import AuthService from "@/services/auth.service";
import ProductService from "@/services/product.service";
// import Carousel from "vue-owl-carousel";
import RetailerService from "@/services/retailer.service";
import PermalinkService from "@/services/permalink.service";
import EmailProductInfoModal from "./EmailProductInfoModal";
import EmailContactCentreModal from "./EmailContactCentreModal";


export default {
  name: "Product",
  components: {
    BaseLayout,
    // Carousel,
    EmailProductInfoModal,
    EmailContactCentreModal
  },
  data: function () {
    return {
      currentUser: null,
      product: null,
      keyFeatures: null,
      retailers: [],
      similarProducts: [],
      selectedProductIds: _.map(
        this.$store.state.product.selectedProducts,
        "id"
      ),
      isSelectedForCompare: true,
      successorProduct: null,
      currentPage: 1,
      perPage: 3,
      generatedUrl: "",
      urlCopiedSuccessfully: false,
      requiredProfileType: false,
    };
  },
  watch: {
    // call again the method if the route changes
    $route: function () {
      this.getCurrentUser();
      this.getProduct();
      this.getSuccessor();
      this.getSimilarProducts();
      this.isSelectedForCompare = true;
    },
  },
  mounted() {
    this.getCurrentUser();
    this.getProduct();
    this.getSuccessor();
    this.getSimilarProducts();
  },
  computed: {
    selectedProducts() {
      return this.$store.state.product.selectedProducts;
    },
    similarProductList() {
      return this.similarProducts.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    rows() {
      return this.similarProducts.length;
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    similarAttributes(attributes) {

      return attributes.filter(function (attr) { return attr.use_for_similar_product; })
    },

    productComparision() {
      for (var i = 0; i < this.selectedProductIds.length; i++) {
        if (this.selectedProductIds[i] == this.product.id) {
          return false;
        }
      }
      return true;
    },

    getRetailers() {
      let params = {
        key: '22441e0a-36ba-4254-8f52-752986e16b16',
        assetId: 1728,
        pid: this.product.code,
        csi_wtbsource: "engineer_app",
        csi_utm_campaign: this.currentUser.id,
      }
      RetailerService.list(params).then((response) => {
        this.retailers = response[0]["RetailerProducts"];
      });
    },

    getSimilarProducts() {
      ProductService.similar(this.$route.params.productId).then((response) => {
        this.similarProducts = response;
      });
    },

    addProductForComparision(product) {
      if (this.selectedProducts.length > 0) {
        if (this.selectedProducts[0].category_id == product.category.id) {
          ProductService.compareEvent(product.id).then(() => {
            this.$store.dispatch("product/add", product);
            this.isSelectedForCompare ^= true;
          });
        } else {
          alert("You can compare products that are in same category.");
        }
      } else {
        ProductService.compareEvent(product.id).then(() => {
          this.$store.dispatch("product/add", product);
          this.isSelectedForCompare ^= true;
        });
      }
    },

    getProduct: function () {
      this.getCurrentUser();
      ProductService.get(this.$route.params.productId).then((response) => {
        this.product = response;
        this.keyFeatures = _.filter(this.product.product_attributes, {
          is_key_feature: true,
        });
        this.getRetailers();
      });
    },

    getSuccessor() {
      ProductService.getSuccessor(this.$route.params.productId).then(
        (successor) => {
          this.successorProduct = successor;
        }
      );
    },

    getCurrentUser: function () {
      AuthService.currentUser().then((user) => {
        this.currentUser = user;
        user.profile_type === "contact_center" ? this.requiredProfileType = true : this.requiredProfileType = false;
      });
    },

    generateUrl() {
      this.urlCopiedSuccessfully = false
      const viewType = "product"
      const productId = [];
      productId.push(this.product.id);
      PermalinkService.create(viewType, productId).then(
        (response) => {
          this.generatedUrl = `https://bshappliancesdirect.co.uk/public/product/${response.uuid}?utm_campaign=${this.currentUser.id}`
          this.$bvModal.show("generate-url-popup")
        },
        (error) => {
          console.log(error)
        }
      );
    },

    copyGeneratedUrl() {
      let generatedUrlToCopy = document.querySelector('#generated-url')
      generatedUrlToCopy.setAttribute('type', 'text')
      generatedUrlToCopy.select()
      var successful = document.execCommand('copy');
      successful ? this.urlCopiedSuccessfully = true : this.urlCopiedSuccessfully = false;
      generatedUrlToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

  },
};
</script>
<style scoped>
.ps {
  height: 200px;
}
</style>
