var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _vm.product
      ? _c(
          "div",
          { attrs: { id: "content" } },
          [
            _c("div", { staticClass: "breadcrumb-wrapper" }, [
              _c("div", { staticClass: "container" }, [
                _c("ul", { staticClass: "breadcrumb" }, [
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/" } }, [
                        _vm._v("Home")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/select-category" } }, [
                        _vm._v(_vm._s(_vm.product.category.tn_parent.name))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/select-sub-category" } },
                        [_vm._v(_vm._s(_vm.product.category.name))]
                      )
                    ],
                    1
                  ),
                  _c("li", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.product.brand.name) +
                        " " +
                        _vm._s(_vm.product.series) +
                        " " +
                        _vm._s(_vm.product.code) +
                        " " +
                        _vm._s(_vm.product.name) +
                        " "
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "product-wrapper" }, [
                  _c("div", { staticClass: "product" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-lg-4 col-md-5 column mb-lg-0" },
                        [
                          _c("div", { staticClass: "slider product-slider" }, [
                            _c("figure", [
                              _c("img", {
                                staticStyle: { width: "auto", height: "auto" },
                                attrs: {
                                  src:
                                    "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                    _vm.product.featured_image +
                                    ".jpg",
                                  alt: _vm.product.code
                                }
                              })
                            ])
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "col-lg-8 col-md-7" }, [
                        _c("div", { staticClass: "details" }, [
                          _c("h4", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.product.brand.name) +
                                " " +
                                _vm._s(_vm.product.series) +
                                " " +
                                _vm._s(_vm.product.code) +
                                " " +
                                _vm._s(_vm.product.name) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "meta" }, [
                            _c("span", { staticClass: "product-code" }, [
                              _vm._v(
                                "Product code: " + _vm._s(_vm.product.code)
                              )
                            ])
                          ]),
                          _vm.product.is_featured
                            ? _c("span", { staticClass: "featured-tag" }, [
                                _vm._v("Buy Direct Model")
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "row align-items-end" }, [
                            _c("div", { staticClass: "col-lg-8" })
                          ]),
                          _c("hr"),
                          _c(
                            "div",
                            { staticClass: "text-lg-right product-buttons" },
                            [
                              _vm.productComparision()
                                ? _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isSelectedForCompare,
                                          expression: "isSelectedForCompare"
                                        }
                                      ],
                                      staticClass:
                                        "theme-bg btn btn-primary-light mb-lg-0 mr-2",
                                      attrs: { id: "compare-product-button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addProductForComparision(
                                            _vm.product
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Compare product")]
                                  )
                                : _vm._e(),
                              _vm.requiredProfileType
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn btn-primary bg-primary-light mr-2",
                                      on: { click: _vm.generateUrl }
                                    },
                                    [_vm._v(" Generate URL ")]
                                  )
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  staticClass: "theme-bg btn btn-primary",
                                  attrs: {
                                    "data-toggle": "modal",
                                    "data-target": "#email-product"
                                  }
                                },
                                [_vm._v(" Email Customer ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "theme-bg btn btn-primary",
                                  attrs: {
                                    "data-toggle": "modal",
                                    "data-target": "#email-contact-centre"
                                  }
                                },
                                [_vm._v(" Email Contact Centre ")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "theme-bg btn btn-primary-light mb-lg-0 mr-2",
                                  attrs: {
                                    href:
                                      "https://media3.bosch-home.com/Documents/specsheet/en-GB/" +
                                      _vm.product.code +
                                      ".pdf",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Download Specification")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "tabs1" }, [
                    _c(
                      "ul",
                      {
                        staticClass: "nav nav-tabs",
                        attrs: { role: "tablist" }
                      },
                      [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "active",
                              attrs: {
                                "data-toggle": "tab",
                                href: "#tab1",
                                role: "tab"
                              }
                            },
                            [_vm._v("Product Info")]
                          )
                        ]),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                "data-toggle": "tab",
                                href: "#tab2",
                                role: "tab"
                              }
                            },
                            [_vm._v("Specification")]
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "tab-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade show active",
                          attrs: { id: "tab1", role: "tabpanel" }
                        },
                        [
                          _c("perfect-scrollbar", [
                            _vm.product.description == "<div></div>"
                              ? _c("div", [
                                  _vm._v(
                                    "Product Information currently not available"
                                  )
                                ])
                              : _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.product.description)
                                  }
                                })
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          attrs: { id: "tab2", role: "tabpanel" }
                        },
                        [
                          _c("perfect-scrollbar", [
                            _vm.product.specifications ==
                            "<div>\n  <ul></ul>\n</div>"
                              ? _c("div", [
                                  _vm._v(
                                    "Product Specification currently not available"
                                  )
                                ])
                              : _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.product.specifications
                                    )
                                  }
                                })
                          ])
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("section", { staticClass: "section pb-0" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "product-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm.similarProductList.length > 0 ||
                      (_vm.successorProduct && _vm.successorProduct.name != "")
                        ? [
                            _c("div", { staticClass: "col-lg-3" }, [
                              _c("header", { staticClass: "mb-4" }, [
                                _c("h3", { staticClass: "theme-text" }, [
                                  _vm._v("Successor model")
                                ])
                              ]),
                              _vm.successorProduct &&
                              _vm.successorProduct.name != ""
                                ? _c(
                                    "div",
                                    { staticClass: "successor-product" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "product theme-bg" },
                                        [
                                          _c("div", { staticClass: "image" }, [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                                  _vm.successorProduct
                                                    .featured_image +
                                                  ".jpg",
                                                alt: ""
                                              }
                                            }),
                                            _vm.product.is_featured
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "featured-tag rotated"
                                                  },
                                                  [_vm._v("Buy Direct Model")]
                                                )
                                              : _vm._e()
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "details" },
                                            [
                                              _c("h4", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.successorProduct.brand
                                                        .name
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.successorProduct
                                                        .series
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.successorProduct.code
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.successorProduct.name
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "btn btn-primary",
                                                  attrs: {
                                                    to:
                                                      /product/ +
                                                      _vm.successorProduct.id,
                                                    id:
                                                      _vm.successorProduct
                                                        .code +
                                                      "-successor-view-button"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      return _vm.$scrollToTop(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" View ")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "info-text" },
                                                [
                                                  _c(
                                                    "i",
                                                    { staticClass: "icon" },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("./../../assets/images/icon5.svg"),
                                                          alt: "img"
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Latest Replacement Model"
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "successor-product mb-4 mb-lg-0"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "product theme-bg" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "details" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  "No direct successor available. Please use similar products or the product finder to offer a replacement."
                                                )
                                              ]),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "theme-bg btn btn-link contact-center-btn",
                                                  attrs: {
                                                    "data-toggle": "modal",
                                                    "data-target":
                                                      "#email-contact-centre"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Email Contact Centre "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-lg-9" },
                              [
                                _vm.similarProductList.length > 0
                                  ? _c("div", [
                                      _c("header", { staticClass: "mb-4" }, [
                                        _c(
                                          "h3",
                                          { staticClass: "theme-text" },
                                          [_vm._v("Similar products")]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "similar-products" },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass: "list1 three-col",
                                              attrs: {
                                                id: "similar-product-list"
                                              }
                                            },
                                            _vm._l(
                                              _vm.similarProductList,
                                              function(product, index) {
                                                return _c(
                                                  "li",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "product"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "image"
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src:
                                                                  "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                                                  product.featured_image +
                                                                  ".jpg",
                                                                alt:
                                                                  product.code
                                                              }
                                                            }),
                                                            product.is_featured
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "featured-tag rotated"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Buy Direct Model"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "details"
                                                          },
                                                          [
                                                            _c("h4", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    product
                                                                      .brand
                                                                      .name
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    product.series
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    product.code
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    product.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]),
                                                            _c(
                                                              "router-link",
                                                              {
                                                                staticClass:
                                                                  "btn btn-primary",
                                                                attrs: {
                                                                  to:
                                                                    /product/ +
                                                                    product.id,
                                                                  id:
                                                                    product.code +
                                                                    "-successor-view-button",
                                                                  replace: ""
                                                                }
                                                              },
                                                              [_vm._v(" View")]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "features-info"
                                                              },
                                                              [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    "Exact Matched Product Features:"
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "ul",
                                                                  _vm._l(
                                                                    _vm.similarAttributes(
                                                                      product
                                                                        .category
                                                                        .category_attributes
                                                                    ),
                                                                    function(
                                                                      category_attribute
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: category_attribute
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "icon"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  attrs: {
                                                                                    src: require("./../../assets/images/icon6.svg"),
                                                                                    alt:
                                                                                      "img"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                category_attribute
                                                                                  .attribute
                                                                                  .name
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.similarProductList.length > 0
                                  ? _c("b-pagination", {
                                      staticClass: "justify-content-end",
                                      attrs: {
                                        "total-rows": _vm.rows,
                                        "per-page": _vm.perPage,
                                        "aria-controls": "similar-product-list"
                                      },
                                      model: {
                                        value: _vm.currentPage,
                                        callback: function($$v) {
                                          _vm.currentPage = $$v
                                        },
                                        expression: "currentPage"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        : [
                            _c("div", { staticClass: "col-lg-12" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-center text-primary font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    "No direct successor or similar products available. Please use product finder to offer a replacement."
                                  )
                                ]
                              )
                            ])
                          ]
                    ],
                    2
                  )
                ]),
                _c("hr")
              ])
            ]),
            _c("section", { staticClass: "section retailers" }, [
              _c("div", { staticClass: "container" }, [
                _c("header", { staticClass: "text-center mb-4" }, [
                  _c("h3", { staticClass: "theme-text" }, [
                    _vm._v("Retailers available to purchase")
                  ])
                ]),
                _c(
                  "ul",
                  { staticClass: "list1" },
                  _vm._l(_vm.retailers, function(retailer) {
                    return _c(
                      "li",
                      { key: retailer.RetailerId, staticClass: "mb-4" },
                      [
                        _c("div", { staticClass: "retailer" }, [
                          _c("div", { staticClass: "image" }, [
                            _c("img", {
                              attrs: {
                                src: retailer.RetailerLogoUrl,
                                alt: retailer.FriendlyName
                              }
                            })
                          ]),
                          _c("div", { staticClass: "details" }, [
                            _c("span", { staticClass: "price" }, [
                              _vm._v(
                                _vm._s(retailer.RetailerCurrencySymbol) +
                                  _vm._s(retailer.Price)
                              )
                            ]),
                            retailer.Availability == "InStock"
                              ? _c("div", { staticClass: "in-stock" }, [
                                  _c("i", { staticClass: "fa fa-check" }),
                                  _vm._v(" In Stock ")
                                ])
                              : _c("div", { staticClass: "in-stock not" }, [
                                  _c("i", { staticClass: "fa fa-times" }),
                                  _vm._v(" Out of Stock ")
                                ]),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-secondary-dark theme-bg",
                                attrs: {
                                  href: retailer.ClickThruUrl,
                                  target: "_blank"
                                }
                              },
                              [_vm._v("View Retailer")]
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ]),
            _c("EmailProductInfoModal", {
              attrs: {
                productId: _vm.product.id,
                currentUser: this.currentUser
              }
            }),
            _c("EmailContactCentreModal"),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "generate-url-popup",
                  centered: "",
                  title: "Generated URL",
                  busy: "true",
                  "hide-footer": ""
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-control d-flex align-items-center testing-code px-3 mb-2"
                  },
                  [
                    _c("span", { staticClass: "code" }, [
                      _vm._v(_vm._s(_vm.generatedUrl))
                    ]),
                    _c("input", {
                      attrs: { type: "hidden", id: "generated-url" },
                      domProps: { value: _vm.generatedUrl }
                    })
                  ]
                ),
                _vm.urlCopiedSuccessfully
                  ? _c(
                      "div",
                      { staticClass: "text-success mb-3 font-weight-bold" },
                      [_vm._v("URL copied to clipboard successfully! ")]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "text-right mb-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary-dark mr-2",
                      on: {
                        click: function($event) {
                          _vm.$bvModal.hide("generate-url-popup")
                          _vm.urlCopiedSuccessfully = false
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.copyGeneratedUrl($event)
                        }
                      }
                    },
                    [_vm._v(" Copy ")]
                  )
                ])
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }