import axios from 'axios';

class PermalinkService {
    async create( viewType, products) {
        const response = await axios
            .post('api/permalinks/', {
                view_type: viewType,
                products: products,
            });
        return response.data;
    }
    async get(uuid) {
        const response = await axios.get(`api/permalinks/${uuid}/`);
        return response.data;
    }

}

export default new PermalinkService();