<template>
  <!-- Modal -->
  <div class="modal fade" id="email-product">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-form">
          <button class="close" data-dismiss="modal"></button>
          <div
            v-if="message"
            class="alert mb-4"
            :class="alertClass"
            role="alert"
          >
            {{ message }}
          </div>
          <h5>Email product information</h5>
          <div v-if="profileType === 'engineer'">
            <p class="mb-2">
              Enter customer's name, job number & email to send product
              information.
            </p>
            <p>
              <span class="font-weight-bold">Please remind the customer: </span
              >They will receive a courtesy call from our team to check in
              following their repair as they can support with a replacement
              appliance.
            </p>
          </div>
          <p v-else>
            Enter customer's name &amp; email to send product information
          </p>
          <ValidationObserver v-slot="{ invalid }" ref="emailForm">
            <form @submit.prevent="submitEmailForm" novalidate>
              <div class="form-group">
                <ValidationProvider name="Name" rules="required" v-slot="v">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    v-model="name"
                    v-bind:class="{
                      'is-invalid': v.invalid && v.validated,
                    }"
                  />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group" v-if="profileType === 'engineer'">
                <ValidationProvider
                  name="Job Number"
                  rules="required|integer"
                  v-slot="v"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Job Number"
                    v-model="jobNumber"
                    maxlength="10"
                    pattern="^237\d{7}$"
                    v-bind:class="{
                      'is-invalid': v.invalid && v.validated,
                    }"
                  />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  name="D&G Job"
                  rules=""
                  v-slot="v"
                  v-if="profileType === 'engineer'"
                >
                  <select
                    name="D&G Job"
                    v-model="dAndGJob"
                    class="custom-select form-control form-control-lg"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }"
                  >
                    <option value="" selected>Select D&amp;G Job</option>
                    <option :value="0">D&amp;G Job</option>
                    <option :value="1">D&amp;G Job New Plan</option>
                  </select>
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] ? "D&G job selection is required" : "" }}
                  </div>
                </ValidationProvider>
              </div>
              <ValidationProvider
                name="E-mail"
                rules="required|email"
                v-slot="v"
                slim
              >
                <div class="input-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    v-model="email"
                    v-bind:class="{
                      'is-invalid': v.invalid && v.validated,
                    }"
                  />
                  <div class="input-group-prepend">
                    <button
                      type="submit"
                      class="btn btn-alt btn-primary theme-bg"
                      :disabled="invalid || profileFormLoading"
                      id="product-details-email-button"
                    >
                      <span
                        v-show="loading"
                        class="spinner-grow spinner-grow-sm"
                        style="width: 1.0625rem; height: 1.0625rem"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Send
                    </button>
                  </div>
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
  <!-- / Modal -->
</template>
<script>
/* global $ */
import ProductService from "@/services/product.service";

export default {
  name: "EmailProductInfoModal",
  props: ["productId", "currentUser"],
  data: function() {
    return {
      message: "",
      loading: false,
      alertClass: "alert-danger",
      email: "",
      name: "",
      jobNumber: "",
      dAndGJob: "",
      profileType: this.currentUser.profile_type,
    };
  },
  methods: {
    submitEmailForm() {
      this.loading = true;

      ProductService.emailInfo(
        this.productId,
        this.name,
        this.email,
        this.jobNumber,
        this.dAndGJob
      ).then(
        () => {
          this.loading = false;
          this.message = "Email successfully sent";
          this.alertClass = "alert-success";

          this.name = "";
          this.email = "";
          this.jobNumber = "";
          this.dAndGJob = "";
          this.$refs.emailForm.reset();
          $("#email-product").modal("hide");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
<style scoped></style>
