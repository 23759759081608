import axios from 'axios';

class RetailerService {
  async list(params) {
    const response = await axios
      .get('https://api.channelsight.com/api/1.47/feed', { params: params });
    return response.data;
  }

  async get(retailerId) {
    const response = await axios
      .get('api/retailers/' + retailerId);
    return response.data;
  }

}

export default new RetailerService();