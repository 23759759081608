<template>
  <!-- Modal -->
  <div class="modal fade" id="email-contact-centre">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-form">
          <button class="close" data-dismiss="modal"></button>
          <div v-if="message" class="alert mb-4" :class="alertClass" role="alert">
            {{ message }}
          </div>
          <h5 class="mb-4">Email Contact Centre</h5>
          <ValidationObserver v-slot="{ invalid }" ref="emailForm">
            <form @submit.prevent="submitEmailForm" novalidate enctype="multipart/form-data">
              <div class="form-group">
                <ValidationProvider name="Job Number" rules="required|integer" v-slot="v">
                  <input type="text" class="form-control" placeholder="Job Number (starts with 237)" maxlength="10"
                    pattern="^237\d{7}$" v-model="jobNumber" v-bind:class="{
                      'is-invalid': v.invalid && v.validated,
                    }" />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label for="" class="form-control file-selector">
                  <span v-if="attachedFile == false">Attachment</span>
                  <span v-else> {{ attachedFile.name }}</span>
                  <ValidationProvider name="invoice" rules="" v-slot="v">
                    <input
                      accept="application/pdf, image/png, image/jpg, image/*, image/jpeg, application/doc, .docx"
                      type="file" @change="selectFile" id="fileInput" />

                    <div class="invalid-feedback text-left">
                      {{ v.errors[0] }}
                    </div>
                  </ValidationProvider>
                </label>
                <p class="text-left note">*Acceptable forms of upload formats are: png, jpg, jpeg, pdf, doc, docx</p>
              </div>

              <ValidationProvider name="E-mail" rules="required|email" v-slot="v" slim>
                <div class="input-group">

                  <input type="email" class="form-control" placeholder="Customer Email address" v-model="email"
                    v-bind:class="{
                      'is-invalid': v.invalid && v.validated,
                    }" />
                  <div class="input-group-prepend">
                    <button type="submit" class="btn btn-alt btn-primary theme-bg"
                      :disabled="invalid || profileFormLoading" id="product-details-email-button">
                      <span v-show="loading" class="spinner-grow spinner-grow-sm"
                        style="width: 1.0625rem; height: 1.0625rem" role="status" aria-hidden="true"></span>
                      Send
                    </button>
                  </div>
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
  <!-- / Modal -->
</template>
<script>
/* global $ */
import ProductService from "@/services/product.service";

export default {
  name: "EmailContactCentreModal",
  data: function () {
    return {
      message: "",
      loading: false,
      alertClass: "alert-danger",
      email: "",
      jobNumber: "",
      attachedFile: false,
    };
  },
  methods: {
    submitEmailForm() {
      this.loading = true;
      var form = new FormData();
      form.append("email_address", this.email);
      form.append("job_number", this.jobNumber);
      if (this.attachedFile) {
        form.append('attached_file', this.attachedFile);
      }
      ProductService.emailContactCentre(form).then(
        () => {
          this.loading = false;
          this.message = "Email successfully sent";
          this.alertClass = "alert-success";

          this.email = "";
          this.jobNumber = "";
          this.attachedFile = false;
          this.$refs.emailForm.reset();
          $('#email-contact-centre').modal('hide')
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    selectFile(e) {
      var fileInput = document.getElementById("fileInput").value;
      var idxDot = fileInput.lastIndexOf(".") + 1;
      var extFile = fileInput.substr(idxDot, fileInput.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "pdf" || extFile == "doc" || extFile == "docx") {
        if (e.target.files.length > 0)
          this.attachedFile = e.target.files[0];
        else {
          console.log(e);
        }
      } else {
        this.message = "Only jpg, jpeg, png, pdf, doc and docx files are allowed!";
      }
    },
  },
};
</script>
<style scoped></style>